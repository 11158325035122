import { IPasswordRecoveriesRepository } from 'modules/authentication/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisPasswordRecoveriesRepository from './implementations/VectisPasswordRecoveriesRepository';

let instance: IPasswordRecoveriesRepository | null = null;

export default function makePasswordRecoveriesRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisPasswordRecoveriesRepository(httpProvider);
  }

  return instance;
}
