import React, { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import {
  makeRemoveSessionUseCase,
  makeSyncUserSessionUseCase,
} from 'modules/authentication/domain/useCases';
import { FullPageLoading } from 'shared/presentation/components/molecules';

import { AuthenticationContext, IAuthenticationContextData } from '../../hooks';

const DefaultAuthenticationProvider: React.FC = ({ children }) => {
  const [session, setSession] =
    useState<IAuthenticationContextData['session']>(null);
  const [initializing, setInitializing] = useState(true);

  const client = useQueryClient();

  useEffect(() => {
    makeSyncUserSessionUseCase()
      .execute()
      .then(session => {
        setSession(session);
        setInitializing(false);
      });
  }, []);

  const logout = useCallback(async () => {
    await makeRemoveSessionUseCase().execute();

    setSession(null);
    client.invalidateQueries({
      inactive: true,
    });
  }, [client]);

  if (initializing) return <FullPageLoading />;

  return (
    <AuthenticationContext.Provider
      value={{
        session,
        login: setSession,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default DefaultAuthenticationProvider;
