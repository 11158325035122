import { AuthenticationGlobalContainer } from 'modules/authentication/presentation/contexts';
import locale from 'modules/authentication/presentation/locale';
import routes from 'modules/authentication/presentation/routes';

const authenticationModule = {
  locale,
  routes,
  Container: AuthenticationGlobalContainer,
};

export default authenticationModule;
