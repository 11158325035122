import { useMemo } from 'react';

import { Helmet } from 'react-helmet';

import { useLocale } from 'shared/presentation/contexts';

interface IDocumentHeadProps {
  title: string;
  article?: {
    title: string;
    description: string;
  };
  canonical?: boolean;
  meta?: Array<{ name: string; description: string }>;
  children?: React.ReactNode;
}

const DocumentHead: React.FC<IDocumentHeadProps> = ({
  title,
  article,
  canonical,
  meta = [],
  children,
}) => {
  const { language } = useLocale();

  const headMeta = useMemo(() => {
    if (!article) return meta;

    return [
      ...meta,
      {
        property: 'og:type',
        content: 'article',
      },
      {
        property: 'og:url',
        content: window.location.href,
      },
      {
        property: 'og:image',
        content: '%PUBLIC_URL%/vialaser-logo.png',
      },
      {
        property: 'og:title',
        content: article.title,
      },
      {
        property: 'og:description',
        content: article.description,
      },
    ];
  }, [article, meta]);

  const link = useMemo(() => {
    if (!canonical) return undefined;

    return [
      {
        href: process.env.REACT_APP_URL + window.location.pathname,
        rel: 'canonical',
      },
    ];
  }, [canonical]);

  return (
    <Helmet
      defaultTitle="Vialaser"
      titleTemplate="%s | Vialaser"
      meta={headMeta}
      link={link}
    >
      <html lang={language} />

      <title>{title}</title>

      {children}
    </Helmet>
  );
};

export default DocumentHead;
