import { createContext, useEffect, useReducer, useContext } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import { UTM_QUERY_PARAMS } from 'shared/presentation/constants';

import useLogger from '../services/Logger';

const ACTIONS = {
  PROCESS_URL: 'process_url',
  LOGOUT: 'logout',
  UPDATE_STATE: 'update_state',
};

export const UTM = {
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CAMPAIGN: 'utm_campaign',
  TERM: 'utm_term',
  CONTENT: 'utm_content',
  FACEBOOK_ID: 'fbclid',
  GOOGLE_ID: 'gclid',
};

const TENANCIAS = {
  BRASIL: 'vialaser',
  PARAGUAI: 'vialaserparaguay',
};

const AppContext = createContext({
  globalState: {},
});

const initialState = {
  hideMenus: false,
  logo: null,
  tenancia: TENANCIAS.BRASIL,
  banners: null,
};

function AuthProvider(props) {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { i18n } = useTranslation();
  const logger = useLogger();

  initialState.tenancia =
    i18n.language === 'pt' ? TENANCIAS.BRASIL : TENANCIAS.PARAGUAI;

  const [state, dispatch] = useReducer((oldState, action) => {
    const newState = {};

    function getNewState(oldState, newState) {
      return { ...oldState, ...newState };
    }

    switch (action.type) {
      case ACTIONS.PROCESS_URL:
        if (window.location.search) {
          const cookies = new Cookies();
          const utms2 = new URL(window.location).searchParams;

          for (const [key, value] of utms2.entries()) {
            if (UTM_QUERY_PARAMS.includes(key)) {
              cookies.set(key, value);
            }
          }
        }

        return { ...oldState, ...newState };

      case ACTIONS.UPDATE_STATE:
        return getNewState(oldState, action.newState);
      default:
        break;
    }
  }, initialState);

  useEffect(() => {
    dispatch({ type: ACTIONS.PROCESS_URL });
  }, []);

  const loadBanners = async () => {
    await axios
      .get('/banners')
      .then(response => {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          newState: { banners: response.data },
        });
      })
      .catch(error => {
        if (process.env.NODE_ENV !== 'test') {
          logger.debug(error);
        }
      });
  };

  useEffect(() => {
    if (i18n.language !== 'pt') return;

    loadBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <AppContext.Provider
      value={{
        globalState: state,
        isUpMd,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  return useContext(AppContext);
}

export { AppContext, AuthProvider, useAppContext };
