export const login = {
  'Could not retrieve social user':
    'No fue posible recuperar el usuario social',
  "Don't have and account? <1>Sign up!</1>":
    'No tienes una cuenta? <1>Registrate!</1>',
  'Forgot your password?': 'Olvidaste tu contraseña?',
  Login: 'Iniciar sesión',
  'Login with Google': 'Iniciar sesión con Google',
  'Login with your Vialaser credentials':
    'Inicia sesión con tus credenciales de Vialaser',
  'Or login with your social networks':
    'O inicia sesión con tus redes sociales',
  'Welcome!': 'Bienvenido/a!',
};
