import lodash from 'lodash';
import { v4 as uuid } from 'uuid';

import IEventTrackerProvider, {
  TMeta,
} from 'shared/domain/providers/IEventTrackerProvider';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IStorageProvider from 'shared/domain/providers/IStorageProvider';

export default class DataLayerEventTrackerProvider
  implements IEventTrackerProvider
{
  private dataLayer: any[];

  constructor(
    private httpProvider: IHTTPProvider,
    private storageProvider: IStorageProvider,
  ) {
    if (!('dataLayer' in window)) {
      lodash.set(window, 'dataLayer', []);
    }

    this.dataLayer = lodash.get(window, 'dataLayer') as any;
  }

  public register(event: string, meta: TMeta, payload: object = {}): void {
    const data = {
      session_id: this.getSessionId(),
      device: meta.device,
      user_id: meta.userId,
      source: 'VIALASER',
      route: meta.route,
      event_type: event,
      env: process.env.REACT_APP_ENV,
      event_data: { ...payload },
    };

    this.dataLayer.push(data);

    this.httpProvider.post('development', JSON.stringify(data));
  }

  private getSessionId() {
    const sessionId = this.storageProvider.get('SESSION_ID');

    if (sessionId) return sessionId;

    const newSessionId = uuid();

    this.storageProvider.store('SESSION_ID', newSessionId);

    return newSessionId;
  }
}
