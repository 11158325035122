import { createContext, useContext } from 'react';

import { IUserSession } from 'modules/authentication/domain/entities';

export interface IAuthenticationContextData {
  session: IUserSession | null;
  login(session: IUserSession): void;
  logout(): void;
}

export const AuthenticationContext = createContext<IAuthenticationContextData>(
  {} as IAuthenticationContextData,
);

export const useAuth = () => useContext(AuthenticationContext);
