import { makeUserSessionsRepository } from 'modules/authentication/infra/repositories';
import { makeHTTPProvider, makeStorageProvider } from 'shared/infra/providers';

import CreateUserSessionUseCase from './implementations/CreateUserSessionUseCase';

let instance: CreateUserSessionUseCase | null = null;

export default function makeCreateUserSessionUseCase() {
  if (!instance) {
    const sessionsRepository = makeUserSessionsRepository();
    const storageProvider = makeStorageProvider();
    const httpProvider = makeHTTPProvider();

    instance = new CreateUserSessionUseCase(
      sessionsRepository,
      storageProvider,
      httpProvider,
    );
  }

  return instance;
}
