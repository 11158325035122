import { useEffect } from 'react';

import {
  TRoutePathsWithParams,
  useNavigation,
  useRoute,
} from 'shared/presentation/contexts';
import { addUtmCookiesToUrl } from 'shared/presentation/helpers';

export default function useAddUtmCookiesToCurrentPage() {
  const navigation = useNavigation();
  const location = useRoute();

  useEffect(() => {
    const { origin, href } = window.location;

    const newHref = addUtmCookiesToUrl(href);

    const hasAnyNewParams = href !== newHref;

    if (!hasAnyNewParams) return;

    const path = newHref.replace(origin, '');
    navigation.replace(path as TRoutePathsWithParams);
  }, [navigation, location.pathname]);
}
