import React from 'react';

import { Route as StandardRoute } from 'react-router-dom';

import { TLanguage } from 'config/tenancy';
import { useAuth } from 'modules/authentication/presentation/contexts';
import { useLocale } from 'shared/presentation/contexts/LocaleContext';

import { useRoute } from '../../hooks';
import { TRoutePaths } from '../../models';
import Redirect from '../Redirect';

interface IRouteProps {
  path: TRoutePaths;
  exact?: boolean;
  component: React.ComponentType<any>;
  disabledTenancies?: TLanguage[];
  authentication?: 'private' | 'public' | 'public_only';
}

const Route: React.FC<IRouteProps> = ({
  path,
  disabledTenancies,
  authentication,
  ...rest
}) => {
  const { language } = useLocale();
  const route = useRoute();
  const auth = useAuth();

  if (disabledTenancies?.includes(language)) return <Redirect to="/" />;

  if (authentication === 'private' && !auth.session) {
    return (
      <Redirect
        to="/login"
        params={{
          origin: encodeURI(route.pathname),
        }}
      />
    );
  }

  if (authentication === 'public_only' && !!auth.session) {
    return <Redirect to="/area-cliente" />;
  }

  return <StandardRoute path={path} {...rest} />;
};

export default Route;
