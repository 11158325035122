/* eslint-disable react-hooks/exhaustive-deps */
import { PAYMENT_TYPES } from 'constants/PaymentTypes';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import ErrorHandler from 'helpers/ErrorHandler';
import { cartItemToFB } from 'helpers/GoogleTagManager/mappers';
import { trackData } from 'helpers/Trackers';
import { UseUTM } from 'hooks/use-utm';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import fetchCart from 'services/cart';
import useLogger from 'services/Logger';

import {
  applyCoupon,
  calculateCartValue,
  getPaymentMethods,
  getPromotions,
  removeCouponFromState,
} from './helpers';
import { AppContext } from '../AppContext';
import { DISCOUNT_TYPES, ITEM_MAPPERS, ITEM_TYPES } from '../core/utils';

export const NewCartContext = createContext({
  items: [],
  businessValue: 0,
  ecommerceValue: 0,
  couponDiscount: 0,
  coupon: undefined,
  discounts: {},
  progressiveDiscountPercentage: 0,
  paymentType: PAYMENT_TYPES.CARTAO_ONLINE,
  defaultPaymentMethods: {},
  paymentMethods: {},
  promotionsConfig: [],
  budgetId: 0,
  unitId: 0,
  count: 0,
  tenancia: '',
  // eslint-disable-next-line no-unused-vars
  setPaymentType: string => undefined,
  setLiquidValue: number => undefined,
  // eslint-disable-next-line no-unused-vars
  addItem: (item, tipo, onSucces, onError) => undefined,
  // eslint-disable-next-line no-unused-vars
  removeItem: (id, tipo) => undefined,
  // eslint-disable-next-line no-unused-vars
  applyCoupon: async (couponCode, onSuccess, onFailure) => undefined,
  removeCoupon: () => undefined,
  // eslint-disable-next-line no-unused-vars
  createBudget: async (onSuccess, onError) => undefined,
  // eslint-disable-next-line no-unused-vars
  hasItem: (id, tipo) => undefined,
  // eslint-disable-next-line no-unused-vars
  resetCart: onComplete => undefined,
  nextProgression: () => undefined,
  getDiscountPercentage: () => undefined,
});

const InitialState = {
  items: [],
  businessValue: 0,
  ecommerceValue: 0,
  couponDiscount: 0,
  coupon: undefined,
  discounts: {},
  progressiveDiscountPercentage: 0,
  paymentType: PAYMENT_TYPES.CARTAO_ONLINE,
  defaultPaymentMethods: {},
  paymentMethods: {},
  promotionsConfig: [],
  budgetId: 0,
  unitId: 0,
  count: 0,
  tenancia: '',
  removedCoupon: false,
};

export function NewCartProvider({ children }) {
  const logger = useLogger();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { globalState } = useContext(AppContext);
  const [state, setState] = useState(() => {
    const storedState = localStorage.getItem('new-cart');

    if (storedState) {
      return {
        ...InitialState,
        ...JSON.parse(storedState),
      };
    }

    return InitialState;
  });

  const [pixDiscountPercentage, setPixDiscountPercentage] = useState(null);
  const [cartDiscountPercentage, setCartDiscountPercentage] = useState(null);
  const [recurrentDiscountPercentage, setRecurrentDiscountPercentage] =
    useState(null);

  /**
   * Atualiza o state e permite definir um callback a ser chamado após o update.
   */
  const updateState = (newStateCallback, callback) => {
    setState(newStateCallback);

    // Se o estado foi resetado, reinicia dados.
    if (JSON.stringify(newStateCallback) === JSON.stringify(InitialState)) {
      initPromotionsAndMethods();
    }

    if (typeof callback === 'function') callback();
  };

  useEffect(() => {
    const { ecommerceValue, businessValue } = calculateCartValue(state.items);

    setState({
      ...state,
      ecommerceValue,
      businessValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.items, state.paymentType]);

  const initPromotionsAndMethods = useCallback(() =>
    Promise.all([getPromotions(), getPaymentMethods(state)]).then(
      ([promocoes, methods]) => {
        return updateState(c => ({
          ...c,
          ...methods,
          ...promocoes,
        }));
      },
    ),
  );

  useEffect(() => {
    initPromotionsAndMethods();
  }, []);

  /**
   * Salva state no LocalStorage a cada alteração.
   */
  useEffect(() => {
    localStorage.setItem('new-cart', JSON.stringify(state));
  }, [state]);

  const getDiscountPercentage = async () => {
    try {
      const { pixPercentage, cartPercentage, recurrentPercentage } =
        await fetchCart.getPaymentMethodsByDiscountPercentage(
          40,
          t,
          enqueueSnackbar,
        );

      setPixDiscountPercentage(pixPercentage);
      setCartDiscountPercentage(cartPercentage);
      setRecurrentDiscountPercentage(recurrentPercentage);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  /**
   * Resets cart state.
   * @param {function} onComplete callback
   */
  const resetCart = onComplete => {
    localStorage.removeItem('new-cart');
    localStorage.removeItem('cartItemsIds');
    updateState(
      {
        ...InitialState,
        defaultPaymentMethods: state.defaultPaymentMethods,
        paymentMethods: state.paymentMethods,
        promotionsConfig: state.promotionsConfig,
      },
      onComplete,
    );
  };

  /**
   * Verifica se o item já existe no carrinho
   *
   * @param {number} id id do item
   * @param {string} tipo tipo do item
   * @returns {boolean}
   */
  const alreadyExists = (id, tipo) => {
    return state.items.findIndex(i => i.id === id && i.tipo === tipo) >= 0;
  };

  /**
   * Verifica se já tem um item com o mesmo tipo no carrinho
   * @param {string} tipo tipo a ser consultado
   * @returns {boolean}
   */
  const alreadyHasType = tipo =>
    state.items.findIndex(i => i.tipo === tipo) >= 0;

  const alreadyHasOtherTypes = (item, tipo) => {
    let exists = false;
    if (tipo === ITEM_TYPES.COMBO) {
      exists = item.pacotes
        .map(pac => pac.id)
        .reduce(
          (bool, id) => bool || alreadyExists(id, ITEM_TYPES.PACOTE),
          false,
        );
    }

    if (tipo === ITEM_TYPES.PACOTE) {
      const { items } = state;
      exists = items
        .filter(it => it.tipo === ITEM_TYPES.COMBO)
        .reduce((pacotes, combo) => [...pacotes, ...combo.pacotes], [])
        .map(pac => pac.id)
        .reduce((bool, id) => {
          return bool || id === item.id;
        }, false);
    }

    return exists;
  };

  /**
   * Adiciona um item ao carrinho do ecommerce caso ele ainda não tenha sido selecionado.
   *
   * @param {object} item Pacote ou combo a ser inserido
   * @param {string:PACOTE|COMBO|PRODUTO} tipo tipo do item a ser inserido
   * @param {function} onSuccess callback chamado quando sucesso
   * @param {function} onError callback chamado quando erro
   */
  const addItem = (item, tipo, onSuccess, onError) => {
    removeCoupon('addItem');
    if (!(tipo in ITEM_TYPES) || alreadyExists(item.id, tipo)) {
      if (typeof onError === 'function') onError('duplicate');
      return;
    }

    if (tipo === ITEM_TYPES.COMBO && alreadyHasType(ITEM_TYPES.COMBO)) {
      if (typeof onError === 'function') onError('too_much_combos');
      return;
    }

    const cartItem = ITEM_MAPPERS[tipo](item);

    if (alreadyHasOtherTypes(cartItem, tipo)) {
      if (typeof onError === 'function') onError('duplicate');
      return;
    }

    trackData(window.location.pathname, 'addToCart', cartItem);
    trackData(window.location.pathname, 'currentCart', [
      ...state.items,
      cartItem,
    ]);

    updateState(current => ({
      ...current,
      items: [...current.items, cartItem],
      removedCoupon: true,
    }));

    if (typeof onSuccess === 'function') onSuccess();
  };

  /**
   * Remove um item do carrinho
   * @param {number} id id do item a ser removido
   * @param {string} tipo tipo do item a ser removido
   */
  const removeItem = async (id, tipo) => {
    const { items, coupon } = state;

    removeCoupon('removeItem');
    const indexToRemove = items.findIndex(
      it => it.id === id && it.tipo === tipo,
    );

    trackData(window.location.pathname, 'remove', items[indexToRemove]);

    items.splice(indexToRemove, 1);

    if (items.length) {
      trackData(window.location.pathname, 'currentCart', [...items]);

      if (coupon) {
        await updateState(s => ({
          ...s,
          items: [...items],
          removedCoupon: true,
        }));
      } else {
        await updateState(s => ({
          ...s,
          items: [...items],
        }));
      }
    } else {
      trackData(window.location.pathname, 'currentCart', []);
      updateState(InitialState);
    }
  };

  useEffect(() => {
    const { coupon, removedCoupon } = state;
    if (removedCoupon && coupon) {
      applyCoupon({
        couponCode: coupon.codigo,
        ecommerceValue: state.ecommerceValue,
        items: state.items,
        unitId: state.unitId,
        onInvalidCoupon: () => removeCoupon('applyCoupon'),
        onSuccess: data =>
          updateState(current => ({
            ...current,
            ...data,
          })),
        onFailure: reason => {
          removeCoupon('applyCoupon');
          enqueueSnackbar(t(reason), {
            variant: 'error',
          });
        },
      });
    }
  }, [state.businessValue, state.ecommerceValue]);

  /**
   * Remove o cupom aplicado no carrinho
   */
  const removeCoupon = async origem => {
    updateState(current => removeCouponFromState(current, origem));
  };

  const setPaymentType = paymentType => {
    if (paymentType in PAYMENT_TYPES) {
      updateState(current => ({
        ...current,
        paymentType,
      }));
    }
  };

  const toItemOrcamento = item => {
    return {
      tipoItem: item.tipo,
      idPacote: ITEM_TYPES.PACOTE === item.tipo ? item.id : null,
      idProduto: ITEM_TYPES.PRODUTO === item.tipo ? item.id : null,
      quantidadeItemOrcamento: 1,
      percentualDesconto: 0,
      valorFinalItemOrcamento: item.valorEcommerce,
      valorTotalItemOrcamento: item.valorEcommerce,
    };
  };

  const submitBudget = (body, onSuccess, onError) => {
    axios
      .post('/newBudget', body)
      .then(response => {
        setState({
          ...state,
          budgetId: response.data.id,
          unitId: response.data.unidade.id,
        });

        localStorage.setItem('budgetId', response.data.id);

        const ids = state.items.map(cartItemToFB);

        const result = ids.map(a => a.id);

        localStorage.setItem('cartItemsIds', JSON.stringify(result));

        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(error => {
        logger.error(error);

        const errorMsg = ErrorHandler(error);
        enqueueSnackbar(t(errorMsg), {
          variant: 'error',
        });

        if (typeof onError === 'function') onError(ErrorHandler(error));
      });
  };

  const getItemsOrcamento = (pacotes, combo) => {
    return [
      ...pacotes.map(packag => toItemOrcamento(packag)),
      ...(combo ? combo.pacotes.map(comb => toItemOrcamento(comb)) : []),
    ];
  };

  /**
   * Cria um novo orçamento à partir dos dados atuais do carrinho.
   *
   * @param {function} onSuccess callbak de sucesso
   * @param {function} onError callback de erro
   */
  const createBudget = async (onSuccess, onError) => {
    const { items, discounts, coupon } = state;

    try {
      const combo = items.find(it => it.tipo === ITEM_TYPES.COMBO);
      const pacotes = items.filter(it => it.tipo === ITEM_TYPES.PACOTE);

      const itensOrcamento = getItemsOrcamento(pacotes, combo);

      const valorTotalItensOrcamento = itensOrcamento.reduce(
        (prevValue, item) => {
          return prevValue + item.valorFinalItemOrcamento;
        },
        0,
      );

      const { utm } = UseUTM();

      const discountType = discounts.COUPON || discounts.DISCOUNT_FIXED;

      const body = {
        idComboVenda: combo ? combo.id : null,
        idCupomPromocao: discountType ? coupon.id : null,
        codigoCupom: discountType ? coupon.codigo : null,
        valorTotalItensOrcamento,
        valorTotalOrcamento: valorTotalItensOrcamento,
        itens: itensOrcamento,
        utm,
      };

      submitBudget(body, onSuccess, onError);
    } catch (error) {
      if (onError) onError();
    }
  };

  const nextProgression = () => {
    const { promotionsConfig: config, items } = state;

    const itemCount = items.reduce(
      (count, item) =>
        item.tipo === ITEM_TYPES.COMBO
          ? count + item.pacotes.length
          : count + 1,
      0,
    );

    const indexOf = (config || []).findIndex(
      a => a.tipo === DISCOUNT_TYPES.PROGRESSIVE,
    );

    if (indexOf < 0) {
      return {
        count: -1,
      };
    }

    const proximaFaixa = config[indexOf].faixas.find(
      a => a.quantidadeItens > itemCount,
    );

    if (!proximaFaixa) {
      return {
        count: -1,
      };
    }

    return {
      count: proximaFaixa.quantidadeItens - itemCount,
      discount: proximaFaixa.percentualDesconto,
    };
  };

  const handleApplyCoupon = useCallback(
    (couponCode, onSuccess = () => undefined, onFailure = () => undefined) =>
      applyCoupon({
        couponCode,
        ecommerceValue: state.ecommerceValue,
        items: state.items,
        unitId: state.unitId,
        onInvalidCoupon: () => removeCoupon('applyCoupon'),
        onSuccess: data => {
          updateState(current => ({ ...current, ...data }));
          onSuccess();
        },
        onFailure: reason => {
          removeCoupon('applyCoupon');
          onFailure(reason);
        },
      }),
  );

  return (
    <NewCartContext.Provider
      value={{
        items: state.items,
        count: state.items.length,
        coupon: state.coupon,
        ecommerceValue: state.ecommerceValue,
        businessValue: state.businessValue,
        paymentType: state.paymentType,
        defaultPaymentMethods: state.defaultPaymentMethods,
        paymentMethods: state.paymentMethods,
        discounts: state.discounts,
        tenancia: globalState.tenancia,
        banners: globalState.banners,
        budgetId: state.budgetId,
        unitId: state.unitId,
        promotionsConfig: state.promotionsConfig,
        progressiveDiscountPercentage: state.progressiveDiscountPercentage,
        nextProgression,
        setPaymentType,
        addItem,
        removeItem,
        applyCoupon: handleApplyCoupon,
        removeCoupon,
        createBudget,
        hasItem: alreadyExists,
        resetCart,
        updateState,
        pixDiscountPercentage,
        cartDiscountPercentage,
        recurrentDiscountPercentage,
        getDiscountPercentage,
      }}
    >
      {children}
    </NewCartContext.Provider>
  );
}

export function useCartContext() {
  return useContext(NewCartContext);
}
