import IOAuthProvider from 'shared/domain/providers/IOAuthProvider';

import GoogleFirebaseOAuthProvider from './implementations/GoogleFirebaseOAuthProvider';

const PROVIDERS = {
  GOOGLE: GoogleFirebaseOAuthProvider,
};

type TProvider = keyof typeof PROVIDERS;

const instances = new Map<TProvider, IOAuthProvider>();

export default function makeOAuthProvider({ type }: { type: TProvider }) {
  if (!instances.has(type)) {
    const Provider = PROVIDERS[type];

    instances.set(type, new Provider());
  }

  return instances.get(type)!;
}
