import axios from 'axios';

import { ICreateUserSessionDTO } from 'modules/authentication/domain/dtos';
import { IUserSessionsRepository } from 'modules/authentication/domain/repositories';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IStorageProvider from 'shared/domain/providers/IStorageProvider';

export default class CreateUserSessionUseCase {
  constructor(
    private sessionsRepository: IUserSessionsRepository,
    private storageProvider: IStorageProvider,
    private httpProvider: IHTTPProvider,
  ) {}

  public async execute({ email, password }: ICreateUserSessionDTO) {
    const { auth, session } = await this.sessionsRepository.create({
      type: 'CREDENTIALS',
      email,
      password,
    });

    this.storageProvider.store('AUTHENTICATION', { auth, session });

    // TODO: Remove axios mention when the entire app starts using httpProvider
    axios.defaults.headers.common.Authorization = `Bearer ${auth.accessToken}`;
    this.httpProvider.setHeader('AUTHENTICATION', `Bearer ${auth.accessToken}`);

    return session;
  }
}
