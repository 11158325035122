import { IUserSessionsRepository } from 'modules/authentication/domain/repositories';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';

const GENDER_DICTIONARY = {
  MASCULINO: 'MALE',
  FEMININO: 'FEMALE',
} as const;

interface IVectisReponse {
  access_token: string;
  refresh_token: string;
  user: { id: number; nome: string; sexo?: 'MASCULINO' | 'FEMININO' };
}

type TCreateParams = Parameters<IUserSessionsRepository['create']>[0];

export default class VectisUserSessionsRepository
  implements IUserSessionsRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public create: IUserSessionsRepository['create'] = async params => {
    if (params.type === 'CREDENTIALS') {
      return this.createFromCredentials(params);
    }

    return this.createFromSocialToken(params);
  };

  private async createFromCredentials({
    email,
    password,
  }: Extract<TCreateParams, { type: 'CREDENTIALS' }>) {
    const response = await this.httpProvider.post<IVectisReponse>(
      'clientes/loginApp',
      {
        email,
        senha: password,
      },
    );

    return this.buildFromVectisResponse(response);
  }

  private async createFromSocialToken({
    token,
  }: Extract<TCreateParams, { type: 'SOCIAL' }>) {
    const response = await this.httpProvider.post<IVectisReponse>(
      'clientes/loginSocial',
      {
        token,
      },
    );

    return this.buildFromVectisResponse(response);
  }

  private buildFromVectisResponse(response: IVectisReponse) {
    return {
      auth: {
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
      },
      session: {
        id: response.user.id,
        name: response.user.nome,
        gender: GENDER_DICTIONARY[response.user.sexo || 'FEMININO'],
      },
    };
  }
}
