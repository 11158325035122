/* eslint-disable camelcase */
import { useEffect } from 'react';

import axios from 'axios';

import { useAuth } from 'modules/authentication/presentation/contexts';
import { makeHTTPProvider, makeStorageProvider } from 'shared/infra/providers';
import { useLocale } from 'shared/presentation/contexts';

const API_URL = {
  pt: process.env.REACT_APP_API_URL,
  es: process.env.REACT_APP_API_URL_PARAGUAY,
  'es-CR': process.env.REACT_APP_API_URL_COSTARICA,
};

export function useInitAxios() {
  const { language } = useLocale();
  const auth = useAuth();

  useEffect(() => {
    axios.defaults.baseURL = API_URL[language];

    // Add a request interceptor
    axios.interceptors.request.use(
      config => {
        config.headers.Language = language;
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    // TODO: Move refresh logic to http provider when all requests are made through it
    axios.interceptors.response.use(undefined, async error => {
      if (error.response && error.response.status === 401) {
        const authentication = makeStorageProvider().get('AUTHENTICATION');
        const refreshToken = authentication.auth.refreshToken;
        if (refreshToken) {
          const response = await axios.get('user/refreshToken', {
            params: {
              token: refreshToken,
            },
          });
          if (response.data) {
            const { access_token } = response.data;
            authentication.auth.accessToken = access_token;
            makeStorageProvider().store('AUTHENTICATION', authentication);
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            error.config.headers.Authorization = `Bearer ${access_token}`;
            makeHTTPProvider().setHeader(
              'AUTHENTICATION',
              `Bearer ${access_token}`,
            );
            return axios.request(error.config);
          }
        } else {
          auth.logout();
        }
      }
      if (error.response && error.response.status === 403) {
        auth.logout();
      }
      return Promise.reject(error);
    });
  }, [language, auth]);
}
