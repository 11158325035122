import { makeUserSessionsRepository } from 'modules/authentication/infra/repositories';
import {
  makeHTTPProvider,
  makeOAuthProvider,
  makeStorageProvider,
} from 'shared/infra/providers';

import PerformSocialLoginUseCase from './implementations/PerformSocialLoginUseCase';

let instance: PerformSocialLoginUseCase | null = null;

export default function makePerformSocialLoginUseCase() {
  if (!instance) {
    const oAuthProvider = makeOAuthProvider({ type: 'GOOGLE' });
    const storageProvider = makeStorageProvider();
    const httpProvider = makeHTTPProvider();
    const repository = makeUserSessionsRepository();

    instance = new PerformSocialLoginUseCase(
      oAuthProvider,
      storageProvider,
      httpProvider,
      repository,
    );
  }

  return instance;
}
