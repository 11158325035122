import React, { ComponentRef, useMemo, useRef } from 'react';

import { useAuth } from 'modules/authentication/presentation/contexts';
import {
  BlackNovemberCountdown,
  NavbarMenuIcon,
} from 'shared/presentation/components/atoms';
import {
  DrawerMenu,
  PackageSearchBox,
} from 'shared/presentation/components/molecules';
import {
  CONTACT_DEFAULT_MESSAGES,
  CONTACTS,
  COUNTRY_CODES,
  ICONS,
} from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';
import { buildWhatsappLink } from 'shared/presentation/helpers';
import { useEventTracker } from 'shared/presentation/hooks';

import * as S from './styles';

const MenuIcon = ICONS.MENU;

const MobileNavbar: React.FC = () => {
  const { t, language } = useLocale('shared');
  const drawerRef = useRef<ComponentRef<typeof DrawerMenu>>(null);
  const auth = useAuth();
  const tracker = useEventTracker();

  const customerName = useMemo(() => {
    const defaultName = t('Visitor');

    if (!auth.session) return defaultName;

    return auth.session.name;
  }, [t, auth.session]);

  return (
    <>
      <S.Container>
        <S.DrawerButton type="button" onClick={() => drawerRef.current?.open()}>
          <MenuIcon />
        </S.DrawerButton>

        <PackageSearchBox />

        <NavbarMenuIcon
          Icon={ICONS.WHATSAPP}
          type={'external'}
          title={'Whatsapp'}
          to={buildWhatsappLink({
            countryCode: COUNTRY_CODES[language],
            number: CONTACTS[language],
            language,
            message: CONTACT_DEFAULT_MESSAGES[language],
          })}
          onClick={() => tracker.register('headerWhatsappButtonClick')}
        />
      </S.Container>

      <BlackNovemberCountdown />

      <DrawerMenu ref={drawerRef} customerName={customerName} />
    </>
  );
};

export default MobileNavbar;
