import { UTM_QUERY_PARAMS } from 'shared/presentation/constants';

const getCookies = () => {
  const pairs = document.cookie.split(';');
  const cookies: Record<string, string> = {};

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    cookies[pair[0].trim()] = pair[1]?.trim();
  }

  return cookies;
};

export default function addUtmCookiesToUrl(url: string) {
  const finalUrl = new URL(url);

  Object.entries(getCookies()).forEach(([key, value]) => {
    if (UTM_QUERY_PARAMS.includes(key)) {
      finalUrl.searchParams.set(key, value);
    }
  });

  return finalUrl.toString();
}
