import { makeHTTPProvider, makeStorageProvider } from 'shared/infra/providers';

import RemoveSessionUseCase from './implementations/RemoveSessionUseCase';

let instance: RemoveSessionUseCase | null = null;

export default function makeRemoveSessionUseCase() {
  if (!instance) {
    const storageProvider = makeStorageProvider();
    const httpProvider = makeHTTPProvider();

    instance = new RemoveSessionUseCase(storageProvider, httpProvider);
  }

  return instance;
}
