import React, { ComponentProps, useMemo } from 'react';

import * as HoverCard from '@radix-ui/react-hover-card';

import { useAuth } from 'modules/authentication/presentation/contexts';
import {
  NavigationMenuItem,
  UserAvatar,
} from 'shared/presentation/components/atoms';
import { CUSTOMER_MENU_LINKS, ICONS } from 'shared/presentation/constants';
import { useLocale, useRoute, useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const ChevronIcon = ICONS.CHEVRON_DOWN;
const GiftIcon = ICONS.GIFT;

const MENU_OPEN_DELAY_MS = 200;
const MENU_OFFSET_PIXELS = 8;

type TMenuItem = ComponentProps<typeof NavigationMenuItem>;

interface IProfileHoverCardProps {
  customerName: string;
}

const ProfileHoverCard: React.FC<IProfileHoverCardProps> = ({
  customerName,
}) => {
  const { t } = useLocale('shared');
  const { theme } = useTheme();
  const { pathname } = useRoute();
  const auth = useAuth();

  const menuItems = useMemo<TMenuItem[]>(() => {
    const items: TMenuItem[] = [
      {
        type: 'link',
        to: '/pacotes',
        icon: ICONS.SHOPPING_BAG,
        text: t('Buy now'),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.CUSTOMER_AREA({ t }),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.SESSIONS({ t }),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.SCHEDULINGS({ t }),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.MY_DATA({ t }),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.RECOMMENDATIONS({ t }),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.CONTRACTS({ t }),
      },
      {
        type: 'button',
        onClick: () => auth.logout(),
        icon: ICONS.LOG_OUT,
        text: t('Exit'),
        color: theme.palette.error.main,
      },
    ];

    return items.map(item => {
      if (item.type !== 'link') return item;

      const isActive = pathname === item.to;

      return {
        ...item,
        color: isActive ? theme.palette.secondary.main : undefined,
      };
    });
  }, [
    t,
    theme.palette.error.main,
    theme.palette.secondary.main,
    pathname,
    auth,
  ]);

  return (
    <HoverCard.Root openDelay={MENU_OPEN_DELAY_MS}>
      <HoverCard.Trigger asChild>
        <S.Trigger>
          <UserAvatar fullName={customerName} />
          <span>{customerName}</span> <ChevronIcon />
        </S.Trigger>
      </HoverCard.Trigger>

      <HoverCard.Portal>
        <S.MenuContent sideOffset={MENU_OFFSET_PIXELS}>
          <S.RecommendWinLink to="/area-cliente/indicacoes">
            <GiftIcon />

            <span>{t('Recommend and Win!')}</span>
          </S.RecommendWinLink>

          {menuItems.map(item => (
            <NavigationMenuItem key={item.text} {...item} />
          ))}
        </S.MenuContent>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default ProfileHoverCard;
