import firebase from 'firebase';

import IOAuthProvider from 'shared/domain/providers/IOAuthProvider';
import 'firebase/auth';

export default class GoogleFirebaseOAuthProvider implements IOAuthProvider {
  private provider: firebase.auth.GoogleAuthProvider;
  private auth: firebase.auth.Auth;

  constructor() {
    firebase.initializeApp({
      apiKey: 'AIzaSyAHX1rUkfqwDBCb3RJMcZbBPphzunv1wO8',
      authDomain: 'vialaser-auth.firebaseapp.com',
      databaseURL: 'https://vialaser-auth.firebaseio.com',
      projectId: 'vialaser-auth',
      storageBucket: 'vialaser-auth.appspot.com',
      messagingSenderId: '999147183124',
      appId: '1:999147183124:web:57f82671222dad5a975b01',
      measurementId: 'G-0DCRV0QQKF',
    });

    this.provider = new firebase.auth.GoogleAuthProvider();
    this.auth = firebase.auth();
  }

  public getUserIdToken: IOAuthProvider['getUserIdToken'] = async () => {
    this.provider.addScope('profile');
    this.provider.addScope('email');
    this.provider.addScope(
      'https://www.googleapis.com/auth/user.birthday.read',
    );
    this.provider.addScope(
      'https://www.googleapis.com/auth/user.phonenumbers.read',
    );

    this.auth.useDeviceLanguage();

    const response = await this.auth.signInWithPopup(this.provider);

    if (!response.user) return null;

    const token = await response.user.getIdToken();

    return token;
  };
}
