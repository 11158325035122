import { IUserSessionsRepository } from 'modules/authentication/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisUserSessionsRepository from './implementations/VectisUserSessionsRepository';

let instance: IUserSessionsRepository | null = null;

export default function makeUserSessionsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisUserSessionsRepository(httpProvider);
  }

  return instance;
}
