import { makePasswordRecoveriesRepository } from 'modules/authentication/infra/repositories';

import { SendPasswordRecoveryUseCase } from './implementations/SendPasswordRecoveryUseCase';

let instance: SendPasswordRecoveryUseCase | null = null;

export default function makeSendPasswordRecoveryUseCase() {
  if (!instance) {
    const repository = makePasswordRecoveriesRepository();

    instance = new SendPasswordRecoveryUseCase(repository);
  }

  return instance;
}
