import axios from 'axios';

import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IStorageProvider from 'shared/domain/providers/IStorageProvider';

export default class RemoveSessionUseCase {
  constructor(
    private storageProvider: IStorageProvider,
    private httpProvider: IHTTPProvider,
  ) {}

  public async execute() {
    // TODO: Remove axios mention when the entire app starts using httpProvider
    delete axios.defaults.headers.common.Authorization;
    this.storageProvider.delete('AUTHENTICATION');
    this.httpProvider.setHeader('AUTHENTICATION', null);
  }
}
