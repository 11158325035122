import { TFunction } from 'i18next';

export const STORE_META_TAGS = (t: TFunction<'shared'>) => [
  {
    name: 'description',
    description: t(
      'Buy hair removial at Vialaser online store An easier way to get your best hair removal package for the best price',
    ),
  },
  {
    name: 'keywords',
    description: t('hair removal, buy hair removal, hair removal price'),
  },
];
