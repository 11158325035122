export const login = {
  'Could not retrieve social user':
    'Não foi possível recuperar o usuário social',
  "Don't have and account? <1>Sign up!</1>":
    'Não tem uma conta? <1>Cadastre-se!</1>',
  'Forgot your password?': 'Esqueceu sua senha?',
  Login: 'Entrar',
  'Login with Google': 'Entrar com Google',
  'Login with your Vialaser credentials': 'Entre com suas credenciais Vialaser',
  'Or login with your social networks': 'Ou faça login com suas redes sociais',
  'Welcome!': 'Bem-vindo(a)!',
};
