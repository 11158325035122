import React, {
  ComponentProps,
  ComponentRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { useCartContext } from 'contexts/NewCartContext';

import { useAuth } from 'modules/authentication/presentation/contexts';
import {
  Modal,
  ModalConsumer,
  NavigationMenuItem,
  UserAvatar,
} from 'shared/presentation/components/atoms';
import { CUSTOMER_MENU_LINKS, ICONS } from 'shared/presentation/constants';
import { Link, Trans, useLocale, useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const GiftIcon = ICONS.GIFT;

type TMenuItem = ComponentProps<typeof NavigationMenuItem> & {
  exclusive?: 'logged' | 'unlogged';
};

interface IDrawerMenuRef {
  open(): void;
}

interface IDrawerMenuProps {
  customerName: string;
}

const DrawerMenu: React.ForwardRefRenderFunction<
  IDrawerMenuRef,
  IDrawerMenuProps
> = ({ customerName }, ref) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);
  const { t } = useLocale('shared');
  const { theme } = useTheme();
  const cart = useCartContext();
  const auth = useAuth();

  useImperativeHandle(
    ref,
    () => ({ open: () => modalRef.current?.open() }),
    [],
  );

  const menuItems = useMemo<TMenuItem[]>(
    () => [
      {
        type: 'link',
        to: '/',
        icon: ICONS.HOME,
        text: t('Home'),
      },
      {
        type: 'link',
        to: '/carrinho',
        icon: ICONS.SHOPPING_CART,
        text: t('Cart'),
        badge: cart.count > 0 ? cart.count : undefined,
      },
      {
        type: 'link',
        to: '/pacotes',
        icon: ICONS.SHOPPING_BAG,
        text: t('Buy now'),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.SESSIONS({ t }),
        exclusive: 'logged',
      },
      {
        type: 'link',
        to: '/agendamento',
        icon: ICONS.CLOCK,
        text: t('Schedule session'),
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.MY_DATA({ t }),
        exclusive: 'logged',
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.RECOMMENDATIONS({ t }),
        exclusive: 'logged',
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.SCHEDULINGS({ t }),
        exclusive: 'logged',
      },
      {
        type: 'link',
        ...CUSTOMER_MENU_LINKS.CONTRACTS({ t }),
        exclusive: 'logged',
      },
      {
        type: 'button',
        onClick: () => {
          modalRef.current?.close();
          auth.logout();
        },
        icon: ICONS.LOG_OUT,
        text: t('Exit'),
        exclusive: 'logged',
        color: theme.palette.error.main,
      },
      {
        type: 'link',
        to: '/login',
        icon: ICONS.LOG_IN,
        text: t('Sign up'),
        exclusive: 'unlogged',
      },
    ],
    [t, theme.palette.error.main, auth, cart.count],
  );

  return (
    <Modal ref={modalRef} align="left">
      <ModalConsumer>
        {({ visible }) => (
          <S.Container visible={visible}>
            <header>
              <div className="title">
                <UserAvatar fullName={customerName} />

                <h3>
                  <Trans t={t} localeKey="Hello,<1></1>{{name}}!">
                    {'Hello,'}
                    <br />
                    {{ name: customerName }}
                    {'!'}
                  </Trans>
                </h3>
              </div>

              {customerName ? (
                <S.RecommendWinLink to="/area-cliente/indicacoes">
                  <GiftIcon />

                  <div>
                    <h3>{t('Recommend and Win!')}</h3>

                    <span>
                      {t('Recommend your friends and gain priceless discounts')}
                    </span>
                  </div>
                </S.RecommendWinLink>
              ) : (
                <S.RegisterSection>
                  <span>{t("Don't have a Vialaser account yet?")}</span>

                  <Link to="/registrar">{t('Click here to sign in!')}</Link>
                </S.RegisterSection>
              )}
            </header>

            <section>
              {menuItems
                .filter(item => {
                  if (!item.exclusive) return true;

                  return (
                    (auth.session && item.exclusive === 'logged') ||
                    (!auth.session && item.exclusive === 'unlogged')
                  );
                })
                .map(item => (
                  <NavigationMenuItem
                    key={item.text}
                    onClick={() => modalRef.current?.close()}
                    {...item}
                  />
                ))}
            </section>
          </S.Container>
        )}
      </ModalConsumer>
    </Modal>
  );
};

export default forwardRef(DrawerMenu);
