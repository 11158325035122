import IPasswordRecoveriesRepository from 'modules/authentication/domain/repositories/IPasswordRecoveriesRepository';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';

export default class VectisPasswordRecoveriesRepository
  implements IPasswordRecoveriesRepository
{
  constructor(private httpProvider: IHTTPProvider) {}

  public create: IPasswordRecoveriesRepository['create'] = email =>
    this.httpProvider.post('clientes/recuperacaoSenha', { email });
}
