import axios from 'axios';

import { IUserSessionsRepository } from 'modules/authentication/domain/repositories';
import { UseCaseError } from 'shared/domain/errors';
import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import IOAuthProvider from 'shared/domain/providers/IOAuthProvider';
import IStorageProvider from 'shared/domain/providers/IStorageProvider';

export default class PerformSocialLoginUseCase {
  constructor(
    private oAuthProvider: IOAuthProvider,
    private storageProvider: IStorageProvider,
    private httpProvider: IHTTPProvider,
    private userSessionsRepository: IUserSessionsRepository,
  ) {}

  public async execute() {
    const token = await this.oAuthProvider.getUserIdToken();

    if (!token) throw new UseCaseError('Could not retrieve social user');

    const { auth, session } = await this.userSessionsRepository.create({
      type: 'SOCIAL',
      token,
    });

    this.storageProvider.store('AUTHENTICATION', { auth, session });

    // TODO: Remove axios mention when the entire app starts using httpProvider
    axios.defaults.headers.common.Authorization = `Bearer ${auth.accessToken}`;
    this.httpProvider.setHeader('AUTHENTICATION', `Bearer ${auth.accessToken}`);

    return session;
  }
}
