import { ISendPasswordRecoveryDTO } from 'modules/authentication/domain/dtos';
import { IPasswordRecoveriesRepository } from 'modules/authentication/domain/repositories';

export class SendPasswordRecoveryUseCase {
  constructor(
    private passwordRecoveriesRepository: IPasswordRecoveriesRepository,
  ) {}

  public async execute({ email }: ISendPasswordRecoveryDTO) {
    return this.passwordRecoveriesRepository.create(email);
  }
}
