import { components } from './components';
import { constants } from './constants';
import { helpers } from './helpers';
import { hooks } from './hooks';
import { validations } from './validations';

export const sharedEs = {
  ...components,
  ...constants,
  ...helpers,
  ...hooks,
  ...validations,
};
