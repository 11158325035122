import { useEffect, useState } from 'react';

import axios from 'axios';

import { useAuth } from 'modules/authentication/presentation/contexts';

import { TNotification } from '../constants';

type TParams = {
  onSuccess(): void;
};

type TVectisAction = {
  id: number;
  acao: string;
};

const ACTIONS_DICTIONARY: Record<string, TNotification['type']> = {
  TOKENIZACAO_CARTAO_PARA_INGENICO: 'INGENICO_TOKENIZATION',
  CLIENTE_SEM_METODO_PAGAMENTO: 'MISSING_PAYMENT_METHOD',
};

export default function useCurrentNotification({ onSuccess }: TParams) {
  const auth = useAuth();

  const [notification, setNotification] = useState<TNotification | null>(null);

  useEffect(() => {
    const userId = auth.session?.id;

    if (!userId) return;

    axios
      .get<TVectisAction[]>(`/clientes/${userId}/notificacaoAcaoCliente`)
      .then(response => {
        for (const action of response.data) {
          if (!(action.acao in ACTIONS_DICTIONARY)) continue;

          setNotification({
            id: action.id,
            type: ACTIONS_DICTIONARY[action.acao],
          });

          return onSuccess();
        }
      })
      .catch(() => undefined);
  }, [auth.session?.id, onSuccess]);

  return { notification };
}
