export const DEFAULT_SPACING = 2;
export const CPF_REGEX = /^([0-9]{3})(\.[0-9]{3}){2}-([0-9]{2})$/i;
export const RUC_REGEX = /^([0-9]{1})(.[0-9]{3})(.[0-9]{2}([0-9])?)$/i;
export const FILTER_AREA_ALL = 'Geral';
export const FILTER_AREA_HEAD = 'Cabeça';
export const FILTER_AREA_BODY = 'Corpo';
export const FILTER_AREA_HANDS_ARMS = 'Braços e mãos';
export const FILTER_AREA_LEGS_FEET = 'Pernas e pés';
export const ORDER_AREA_NAME = 'Nome';
export const ORDER_AREA_RANKING = 'Mais Vendidos';
export const BANNER_WIDTH_MAPPING = {
  xs: 'XS',
  sm: 'SM',
  md: 'SM',
  lg: 'MD',
  xl: 'XXL',
};
