export const UTM_QUERY_PARAMS = [
  'utm_campaign',
  'utm_term',
  'utm_source',
  'utm_medium',
  'utm_content',
  'ltk_gcm',
  'ltk_gag',
  'ltk_gac',
  'ltk_gne',
  'gclid',
];
