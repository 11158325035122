import { makeHTTPProvider, makeStorageProvider } from 'shared/infra/providers';

import SyncUserSessionUseCase from './implementations/SyncUserSessionUseCase';

let instance: SyncUserSessionUseCase | null = null;

export default function makeSyncUserSessionUseCase() {
  if (!instance) {
    const storageProvider = makeStorageProvider();
    const httpProvider = makeHTTPProvider();

    instance = new SyncUserSessionUseCase(storageProvider, httpProvider);
  }

  return instance;
}
